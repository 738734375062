<template>
    <modal ref="modalConfigurarEquipo" titulo="Configurar los administradores del equipo" :modal-cargado="cargando" adicional="Guardar" no-aceptar @adicional="editarTeamUsers">
        <div class="row mx-0 justify-center">
            <div class="col-10">
                <el-select
                v-model="idAdmin"
                filterable
                clearable
                remote
                class="w-100"
                reserve-keyword
                :remote-method="remoteMethod"
                @change="agregarAdmin"
                >
                    <el-option
                    v-for="item in admins"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    :disabled="item.disabled"
                    />
                </el-select>
            </div>
        </div>
        <div class="row mx-0 py-2 pb-4" />
        <div class="row mx-0 justify-center">
            <div v-for="(data,a) in asignados" :key="a" class="col-9 px-2 d-middle bg-light-f5 border br-6 py-2 mb-2">
                <img :src="data.imagen" width="28" height="28" class="obj-cover rounded-circle" />
                <div class="col text-center text-general f-15">
                    {{ data.nombre }}
                </div>
                <div class="col-auto px-2">
                    <i v-if="a != 0" class="icon-cancel text-general f-20 cr-pointer" @click="eliminarAdmin(a)" />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Teams from "~/services/teams";
export default {
    data(){
        return{
            cargando: false,
            idAdmin: null,
            admins: [],
            asignados: [],
            idsAsignados: [],
            team: {}
        }
    },
    methods: {
        toggle(data){
            this.team = data
            this.asignados = data.users
            this.remoteMethod('')
            this.$refs.modalConfigurarEquipo.toggle();
        },
        async editarTeamUsers(){
            try {
                let model = {
                    id: this.team.id,
                    admins: this.asignados
                }
                this.cargando = true
                const {data} = await Teams.editarTeamUsers(model.id, model);
                this.notificacion('Mensaje', 'Creado exitosamente', 'success');
                this.$emit('actualizar');
                this.$refs.modalConfigurarEquipo.toggle();
            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargando = false
            }
        },
        async remoteMethod(query){
            try {
                let params = {
                    query,
                    team: this.team.id
                }
                const { data } = await Teams.listaAdministradores(params)
                data.map(el => el.disabled = !!el.disabled)
                this.admins = data
            } catch (e){
                this.admins = []
            }
        },
        agregarAdmin(val){
            let leechero = this.admins.find(el => el.id == val)
            this.idsAsignados.push(val)
            this.asignados.push(leechero)
            this.idAdmin = null
        },
        eliminarAdmin(l){
            this.asignados.splice(l,1)
            this.idsAsignados.splice(l,1)
        },
    }
}
</script>
